import { chase, cImage1, cImage2, cImage3, cImage4, cImage5, cImage6, hamid, imgRoadmap1, imgRoadmap2, imgRoadmap3, imgRoadmap4, imgRoadmap5, imgRoadmap6, luke, mbot, mohammad, mono, omotosho, rahul, roadmapSphere1, roadmapSphere2, roadmapSphere3, roadmapSphere4, roadmapSphere5, roadmapSphere6, sphereGreen, sphereRainbow, WImage1, WImage2, WImage3, yullia } from "../assets";

export const products = [
    {
        id: 1,
        img: imgRoadmap1,
        sphere: roadmapSphere1,
        category: 'Our Vision',
        title: `Roob's Community`,
        subtitle: 'Keystone',
        desc: '<p>We are bridging art and utility while building a great community around our digital characters for all members in the entertainment industry.Everybody should have a meta identity and be part of the metaverse future.</p><p>We are creating an exclusive “ Roobs Community “.</p>',
    },
    {
        id: 2,
        img: imgRoadmap2,
        sphere: roadmapSphere2,
        category: 'WHEN EVERYTHING STARTED',
        title: `just an Idea`,
        subtitle: 'The Beginning',
        desc: '<p>Project was founded by a team of 5 designers and artists in September 2021. They wanted to create a unique concept different to anything around. They have started working on a short story about 5 characters.</p><p> Each character is based on each of the designer’s artist’s style and personality.</p>',
        more: '<p>The created a book called “ The Chronicles of Roob “. It’s a story book talking about the adventure of 5 characters. Roob the Sloth , Boof the Mammoth, Toot the Squirrel, Hooch the Tiger & Wooz the Weasel</p><p>The team then started on the first draft of ``Sloth Roob `` character in such way that is connected to the revolution of the metaverse entrainment.</p><p>5353 unique iconic digital characters of Sloth Roobs were created, and they will be ready to be in the Ethereum universe.</p>'
    },
    {
        id: 3,
        img: imgRoadmap3,
        sphere: roadmapSphere3,
        category: 'THE NEW ERA IS ABOUT TO BEGIN',
        title: `What's Next?`,
        subtitle: 'The Future',
        desc: '<p>5353 unique iconic digital characters of Sloth Roobs will be ready to connect with their holders and give them a unique meta identity soon.</p><p> They will be available to mint on ERC-721 smart contract on the Ethereum blockchain very soon. Sloth Roobs are PFP with instant rarity ranking and unique meta identity for their holders.</p>',
        more: '<p>Upon the completion of the mint process of Sloth Roob, our team will be releasing the next phase of the project which is the launch of the other 4 collections Boof the Mammoth, Toot the Squirrel, Hooch the Tiger & Wooz the Weasel.</p><p>Upon the completion of the 5 collections, our team will partner with a well-known story writer ( Top Secret ) publishing the full story book of “ Chronicles of Roob “.</p>'
    },
    {
        id: 4,
        img: imgRoadmap4,
        sphere: roadmapSphere4,
        category: 'ONE COMMUNITY',
        title: `Exclusivity`,
        subtitle: 'Perks for Holder',
        desc: '<p>- Holders of these unique NFTs will giving priority position to acquire future released NFTs . Yes, we call it “ Roobs List “ , we don’t call it “ Whitelist “ when you are with us.</p><p> - Collect, Trade & have fun with our NFTs in market places .</p>',
        more: '<p>Special edition of “ The Chronicles of Roob “ story book once published. Exclusive membership to the “ Roobs “ club and their community giveaways.</p><p>- Exclusive access to projects voting system and holders votes.</p><p>- Exclusive access to partner’s collection at a discounted price.</p>'
    },
    {
        id: 5,
        img: imgRoadmap5,
        sphere: roadmapSphere5,
        category: 'TEASER PROJECT',
        title: `Airdrop Collection`,
        subtitle: 'Boss Sloth Roob - Genesis Editions',
        desc: '<p>The 100 Boss Sloth Roob Genesis Editions are all uniquely designed original Sloth Roobs living on the Ethereum Blockchain.</p>',
        more: '<p>These are the first generated Sloth Roobs, with scenic environments, designs and perks for the holders. Genesis Editions Holders will be entitled to a special perks from future collections.</p><p>DWCC launched this collection back in Feb 2022 to show off their art and vision. This collection was part of our brand marketing to our art and vision to connect with art lovers and fans.</p><p>Majority of the items were airdropped to our fans. Lets call it a teaser trailer collection for our main event that still to come.</p>'
    },
    {
        id: 6,
        img: imgRoadmap6,
        sphere: roadmapSphere6,
        category: 'THE SKY IS THE LIMIT',
        title: `The First Collection`,
        subtitle: 'Sloth Roob Official Collection',
        desc: '<p>Sloth Roob is the first most iconic digital character from DWCC’s book ”The Chronicles of Roob”.</p>',
        more: "<p>Some would argue Sloth Roob is from the ice age period. Sloth Roob was in a deep, centuries-long sleep and just woke up.</p><p>After a century-long sleep, a total of 5353 Sloth Roobs are now roaming the Ethereum metaverse in search of their friends human fans & collectors.</p><p>Sloth Roob NFT is a collection of 5353 unique iconic digital characters inspired by the Digital Web Creation Club's book - ``The Chronicles of Roob``.</p><p>This limited edition comprises characters that have its special rarity and traits providing a secure certification of authenticity.</p><p>We are building something phenomenal as DWCC will become a premier hub for crypto enthusiasts, NFTs and SlothRoob fans.</p><p>Owners of these digital NFTs will be entitled to special perks in the Sloth Roob metaverse in the future as this is a key that grants holders entrance to the metaverse we are building.</p><p>Sloth Roob NFTs are yours to trade and collect.</p>"
    },
]

export const roadmaps = [
    {
        id: 1,
        sphere: sphereGreen,
        date: 'Q3 2021',
        text: 'Formation of team and advisors for development of the project & Creation of project architecture and concept.'
    },
    {
        id: 2,
        sphere: sphereGreen,
        date: 'Q4 2021',
        text: 'The first draft of the "Sloth Roob " character. Sloth Roob is the first most iconic digital character “Sloth Roob” from DWCC’s book ” The Chronicles of Roob”.'
    },
    {
        id: 3,
        sphere: sphereGreen,
        date: 'Q1 2022',
        text: '- DWCC Official website lunch. - "The Chronicles of Roob" first edition book release - Airdrop of 100 Boss Sloth Roob - Genesis 100 Editions Collection ( Teaser Collection ).'
    },
    {
        id: 4,
        sphere: sphereRainbow,
        date: 'Q2 2022',
        text: '- Launch of 5353 Sloth Roob Official Collection. - The beginning of the search mission is to find the SlothRoob friend’s on the Ethereum network. MammothBoof, SquirrelToot, TigerHooch & WeaselWooz.'
    },
    {
        id: 5,
        sphere: sphereRainbow,
        date: 'Q3 2022',
        text: 'The Launch of MammothBoof, SquirrelToot, TigerHooch & WeaselWooz Collections in the Metaverse.'
    },
    {
        id: 6,
        sphere: sphereRainbow,
        date: 'Q4 2022',
        text: 'The Chronicles of Roob Second Edition Book Release with publishing house ( Yes, Celebrity Writer ).'
    },
    {
        id: 7,
        sphere: sphereRainbow,
        date: 'Q1 2023',
        text: 'Top Secret to be revealed'
    },
]

export const collections = [
    { id: 1, title: 'Genesis Boss Sloth Roob', img: cImage1 },
    { id: 2, title: 'Sloth Roob Official Collection', img: cImage2 },
    { id: 3, title: 'MammothBoof', img: cImage3, soon: true },
    { id: 4, title: 'SquirrelToot', img: cImage4, soon: true },
    { id: 5, title: 'TigerHooch', img: cImage5, soon: true },
    { id: 6, title: 'WeaselWooz', img: cImage6, soon: true },
]

export const whitepapers = [
    { id: 1, title: 'Whitepaper', img: WImage1, link: 'https://digital-web-creation-club.gitbook.io/digital-web-creation-club-whitepaper/dwcc-whitepaper/overview' },
    { id: 2, title: 'Licensing Rights', img: WImage2, link: 'https://digital-web-creation-club.gitbook.io/digital-web-creation-club-licensing-agreement/digital-web-creation-club-licensing-agreement' },
    { id: 3, title: 'The Chronicles of Roob', img: WImage3, link: 'https://online.flippingbook.com/view/1040088348/'},
]

export const team = [
    { id: 1, title: 'Mono', img: mono, position: 'Mono, Co-founder, Artist & Business Manager', social: [] },
    // { id: 2, title: 'Robino', img: teamImage1, position: 'Co-founder, Artist & Community Manager', social: [] },
    { id: 3, title: 'Luke', img: luke, position: 'Co-founder, Business Development', social: [] },
    { id: 4, title: 'Chase', img: chase, position: 'Co-founder, Artist & Project Manager', social: [] },
    { id: 5, title: 'Mohammad', img: mohammad, position: 'Co-founder, Artist & Marketing Manager', social: [] },
    { id: 6, title: 'Yullia', img: yullia, position: 'Illustration & Pixels', social: [] },
    { id: 7, title: 'Omotosho', img: omotosho, position: 'Technology', social: [] },
    { id: 8, title: 'Hamid', img: hamid, position: 'Marketing & Community Engagement', social: [] },
    { id: 9, title: 'Rahul', img: rahul, position: 'Graphic Designer', social: [] },
    { id: 10, title: 'Mbot', img: mbot, position: 'Writer & Content Creation', social: [] },
]

export const faq = {
    first: [
        {
            id: 1, title: 'What is Digital Web Creation Club "DWCC"?',
            text: `
            <p> Web Creation Club “DWCC”  is the premium hub where their leads artists, designers & creators are generating unique, never stopping digital collectibles characters, non-fungible token & content on the blockchain.</p>
            <p> Beyond books , our aim is to provide compounding values and multimedia possibilities for our readers using decentralized technology.</p> 
            <p> Paradoxically, decentralized technology can help people to experience poetry and literature in ancient fashion: As a shared, direct experience, with barriers removed between poet and audience, Web3 encourages peer-to-peer interactions between writers and readers — now reincarnated as reader-collectors.</p> 
            <p> The DWCC Ecosystem provides unending possibilities. Our digital collections inspired by books we write are original and state-of-the-art.</p>
            <p> These products start with an NFT series, but project forward a roadmap under which holders of the NFT gain access to an expanding array of products, activities, and experiences. Revenue from initial and subsequent NFT sales is fed back into the brand, supporting increasingly ambitious projects — which in turn drive up the value of the NFTs themselves.</p>
            <p>Thus owning an NFT effectively makes you an investor, a member of a club, a brand shareholder, and a participant in all the top perks for holders.</p>
            `
        },
        {
            id: 2, title: 'Who is behind Digital Web Creation Club "DWCC"?',
            text: `
            <p> Mono, Co-founder, Artist & Business Manager.</p>
            <p>Luke, Co-founder, Business Development.</p>
            <p>Chase, Co-founder, Artist & Project Manager.</p>
            <p> Mohammad, Co-founder, Artist & Marketing Manager.</p>
            <p>Yullia, Illustration & Pixels.</p>
            <p>Omotosho, Technology Development.</p>
            <p>Hamid, Marketing & Community Engagement.</p>
            <p>Rahul, Graphic Designer.</p>
            <p>Mbot, Writer & Content Creation.</p>
        `
        },
        {
            id: 3, title: 'How many NFT collections will be created by Digital Web Creation Club "DWCC" ?',
            text: `
            <p>Total of 5 collections will be created by Digital Web Creation Club “DWCC”.</p>
            <p>1. SlothRoob ( Genesis Edition Boss Sloth Roob & Sloth Roob Official Collection ).</p>
            <p>2. MammothBoof</p>
            <p>3. SquirrelToot<p>
            <p>4. TigerHooch</p>
            <p>5. WeaselWooz</p>
        `
        },
        {
            id: 4, title: 'How many NFTs in each collection will be there?',
            text: `
            Each collection varies from 5353 – 100 uniquely generated digital collectibles living on the Ethereum blockchain Created by Digital Web Creation Club “DWCC”.
        `
        },
        {
            id: 5, title: 'What blockchain is being used?',
            text: `
            We use ERC-721 tokens on the Ethereum blockchain for our NFTs. Alternative ERC standards plus other Layer 1 & 2 solutions are being explored and appraised for future use either in airdrops or mints.
        `
        },
        {
            id: 6, title: 'Who can I contact if I have more questions?',
            text: `
            <p> ✔ Linktree <a target="_blank" rel="nofollow" href="https://linktr.ee/DigitalWebCreationClub">https://linktr.ee/DigitalWebCreationClub</a></p>
            <p> ✔ Website <a target="_blank" rel="nofollow" href="https://digitalwebcreation.com/">https://digitalwebcreation.com/</a></p>
            <p> ✔ Discord <a target="_blank" rel="nofollow" href="https://discord.gg/dwcc">https://discord.gg/dwcc</a></p>
            <p> ✔ Twitter <a target="_blank" rel="nofollow" href="https://twitter.com/SlothRoob">https://twitter.com/SlothRoob</a></p>
            <p> ✔ Facebook <a target="_blank" rel="nofollow" href="https://www.facebook.com/TheChroniclesofRoob">https://www.facebook.com/TheChroniclesofRoob</a></p>
            <p> ✔ Instagram <a target="_blank" rel="nofollow" href="https://www.instagram.com/sloth_roob">https://www.instagram.com/sloth_roob</a></p>
            <p> ✔ YouTube <a target="_blank" rel="nofollow" href="https://www.youtube.com/channel/UCNroE867f5ixB-3Lf2wkZIg">https://www.youtube.com/channel/UCNroE867f5ixB-3Lf2wkZIg</a></p>
            <p> ✔ TikTok <a target="_blank" rel="nofollow" href="https://www.tiktok.com/@slothroobnft">https://www.tiktok.com/@slothroobnft</a></p>
            <p> ✔ Medium <a target="_blank" rel="nofollow" href="https://digitalwebcreation.medium.com/">https://digitalwebcreation.medium.com/</a></p>
        `
        },
        {
            id: 7, title: 'How do I invite Friends to Digital Web Creation Club "DWCC" Community?',
            text: `
            <p>Through Discord. By clicking on the Digital Creation Club Discord channel, then click on the invite friends button. You can copy and share the link generated.</p>
            <p>To avoid the default 7 days expiration ultimatum, click on ‘Edit invite lin’ and set the expiration to never.</p>
        `
        },
    ],
    second: [
        {
            id: 1, title: 'Where can I purchase/mint/redeem?',
            text: `
            <p>You can only mint on our site soon.</p>
        `
        },
        {
            id: 2, title: 'When can I purchase/mint/redeem?',
            text: `
            <p>We are going to have only one minting period. The Public Sale will be announced on our social media pages and on our website.</p>
        `
        },
        {
            id: 3, title: 'What is a redeemable free mint?',
            text: `
            <p>
            A redeemable free mint is a free NFT for which you only have to pay gas and transaction fees.
            </p>
        `
        },
        {
            id: 4, title: 'When is the Public Sale?',
            text: `
            <p>We are going to have only one minting period. The Public Sale will be announced on our social media pages and on our website.  Ensure to use only links from the official DWCC Discord server channel.</p>
        `
        },
        {
            id: 5, title: 'How do I mint in the Public Sale?',
            text: `
            <p>Through the minting page and link your registered wallet.</p>
            <p>The site will display the current auction price and the quantity of mint available for purchase. The next step is for you to choose the quantity you would like to purchase, not exceeding the maximum amount per transaction, and then click on the Mint Button. A prompt will appear on your screen displaying the transaction and gas fees incurred. Click on the ‘Confirm” button to complete your purchase.</p>

        `
        },
        {
            id: 6, title: 'What is the next stage in the event where the Public Sale doesn’t sell out?',
            text: `
            <p>The Sale will continue until the NFTs are sold out or the team determines another course of action.</p>
           `
        },
        {
            id: 7, title: 'Public Sale or Secondary market; when is the best time to mint?',
            text: `
            <p>This largely depends on the buyer and his aims.</p>
           `
        },
        {
            id: 8, title: 'What are Gas fees?',
            text: `
            <p>Gas fees are service fares paid to the miner who is charged with executing the cryptographic computation to decode the issue enabling user verification and security of a block to be put onto the blockchain immutably. Gas fees are paid through the smart contracts. And the amount varies with time, demand, and congestion on the blockchain network.</p>
           `
        },
    ]
}


export const menu = [
    'Home',
    'Roadmap',
    'Collections',
    'Whitepapers',
    'Faq',
    'Team',
    'Contact',
    // 'Mint'
]

export const PATHS = {
    home: '/',
    mint: 'mint',
    rarity: 'rarity',
}

export const EMAILJS = {
    publicKey: 'AhWTks5pvWB3cI5PJ',
    serviceId: 'service_i2pc17z',
    templateId: 'template_lp4flwg',
}

export const  PRICE = 0.02;