import cn from "classnames";
import { Link } from "react-router-dom";
import {
    Arrow,
  cSphere1,
  cSphere2,
  iconDiscordWhite,
  promoVideo,
  sphere,
  vision1,
  vision2,
  vision3,
  vision4,
  warped1,
  warped2,
  warped3,
  warped4,
  warped5,
  warped6,
  warped7,
} from "../../assets";
import Collapse from "../../components/Collapse";
import CollectionsCard from "../../components/CollectionsCard";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import Header from "../../components/Header";
import ProductCard from "../../components/ProductCard";
import TeamCard from "../../components/TeamCard";
import WhitepapersCard from "../../components/WhitepapersCard";
import {
  PATHS, 
  collections,
  faq,
  products,
  roadmaps,
  team,
  whitepapers,
} from "../../utils";
import styles from "./home.module.scss";

const Home = () => {
  return (
    <>
      <section id="home" className={styles.intro}>
        <Header />
        <div className="container">
          <div className={styles.intro__wrap}>
            <div className={styles["intro-block"]}>
              <h1 className={styles["intro-block__title"]}>
                Digital Web Creation Club
              </h1>
              <p className={styles["intro-block__desc_first"]}>
                Get You Meta Identity
              </p>
              <p className={styles["intro-block__desc_second"]}>
                Become part of The Future
              </p>
              <a target='_blank' rel="noreferrer" className={styles["intro-block__btn"]} href="https://discord.gg/dwcc">
                <img src={iconDiscordWhite} alt="Join" /> join our discord
              </a>
              {/* <Link className={styles['intro-block__btn-mint']} to={PATHS.mint}>Mint now <Arrow className={styles['intro-block__btn-mint_icon']} /></Link> */}
            </div>
            <div className={styles["intro-block-images"]}>
              <img src={warped1} alt="Character" loading="lazy" />
              <img src={warped2} alt="Character" loading="lazy" />
              <img src={warped3} alt="Character" loading="lazy" />
              <img src={warped4} alt="Character" loading="lazy" />
              <img src={sphere} alt="Sphere" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      <section id="products" className={styles.products}>
        <div className="container">
          <div className={styles["products__wrap"]}>
            {products.map((item, idx) => (
              <ProductCard key={idx} {...item} />
            ))}
          </div>
        </div>
      </section>
      <section id="video" className={styles.video}>
        <div className="container">
          <video className={styles.video__wrap} width="auto" height="auto" controls>
            <source src={promoVideo} type="video/mp4" />
          </video>
        </div>
      </section>
      <section id="our-vision" className={styles["our-vision"]}>
        <div className="container">
          <h2 className={styles["our-vision__title"]}>Our Vison</h2>
          <div className={styles["our-vision__wrap"]}>
            <div>
              <img src={vision1} alt="Vision" />
              <h4>Unique Meta Identity</h4>
            </div>
            <div>
              <img src={vision2} alt="Vision" />
              <h4>Become Part of the Future</h4>
            </div>
            <div>
              <img src={vision3} alt="Vision" />
              <h4>Ecosystem Mapping</h4>
            </div>
            <div>
              <img src={vision4} alt="Vision" />
              <h4>Digital Entertainment</h4>
            </div>
          </div>
        </div>
      </section>
      <section id="roadmap" className={styles["roadmap"]}>
        <h2 className={styles["roadmap__title"]}>Roadmap</h2>
        <div className="container">
          <div className={styles["roadmap__images"]}>
            <img src={warped5} alt="Roadmap" />
            <img src={warped6} alt="Roadmap" />
            <img src={warped7} alt="Roadmap" />
          </div>
          <div className={styles.timeline}>
            {roadmaps.map((item, idx) => (
              <div key={idx} className={styles.timeline__item}>
                <div
                  className={cn(
                    styles["timeline__img"],
                    styles[`timeline__img_${item.id}`],
                    {
                      [styles["timeline__img_last"]]:
                        idx === roadmaps.length - 1,
                    }
                  )}
                >
                  <img src={item.sphere} alt={item.title} />
                </div>
                <div className={styles["timeline__block"]}>
                  <time
                    className={cn(styles["timeline__time"], {
                      [styles["timeline__time_violet"]]: idx > 2,
                    })}
                  >
                    {item.date}
                  </time>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="collections" className={styles.collections}>
        <div className="container">
          <div className={styles["collections__title-wrap"]}>
            <div>
              <h4 className={styles["collections__sub-title"]}>
                CREATIVE MODERN LIMITED NFT COLLECTIONS
              </h4>
              <h2 className={styles["collections__title"]}>
                Our NFT Collections
              </h2>
            </div>
            <img src={cSphere1} alt="Sphera" />
          </div>
        </div>
        <div className={styles["collections__wrap"]}>
          <div className="container">
            <div className={styles["collections-cards"]}>
              {collections.map((item) => (
                <div
                  className={cn(
                    styles["collections__card"],
                    styles[`collections__card_${item.id}`]
                  )}
                  key={item.id}
                >
                  <CollectionsCard {...item} />
                </div>
              ))}
            </div>
            <img
              className={styles["collections__sphere"]}
              src={cSphere2}
              alt="Sphera"
            />
          </div>
        </div>
      </section>
      <section id="whitepapers" className={styles.whitepapers}>
        <div className="container">
          <h4 className={styles["whitepapers__sub-title"]}>our files</h4>
          <h2 className={styles["whitepapers__title"]}>Whitepapers</h2>
          <div className={styles["whitepapers__wrap"]}>
            {whitepapers.map((item) => (
              <WhitepapersCard key={item.id} {...item} />
            ))}
          </div>
        </div>
      </section>
      <section id="chill-time" className={styles["chill-time"]}>
        <div className="container">
          <h4 className={styles["chill-time__sub-title"]}>CHILL TIME</h4>
          <h2 className={styles["chill-time__title"]}>
            "The Chronicles of Roob"
          </h2>
          <h3 className={styles["chill-time__green-text"]}>
            First Edition Audiobook
          </h3>
        </div>
      </section>
      <section id="faq" className={styles["faq"]}>
        <div className="container">
          <h4 className={styles["faq__sub-title"]}>faq</h4>
          <h2 className={styles["faq__title"]}>Frequency Asked Questions</h2>
          <div className={styles["faq__collapses"]}>
            {faq.first.map((item) => (
              <Collapse key={item.id} title={item.title}>
                {item.text}
              </Collapse>
            ))}
          </div>
          <div className={styles.howbuy}>
            <h2 className={styles.howbuy__title}>
              How To Buy Non-Fungible Token
            </h2>

            {faq.second.map((item) => (
              <Collapse key={item.id} title={item.title}>
                {item.text}
              </Collapse>
            ))}
          </div>
        </div>
      </section>
      <section id="team" className={styles.team}>
        <div className="container">
          <h4 className={styles["team__sub-title"]}>OUR META BRAINS</h4>
          <h2 className={styles.team__title}>The Team</h2>
          <p className={styles["team__desc"]}>
            Our team is an excellent combination of technology and strategy.
            Most of the DWCC team members are experts from the traditional art
            industry with experience in renowned art institutions. Our core
            members include professional engineers with blockchain and
            cryptography related experience from leading internet companies.
            DWCC is a leading player in the NFT field both in terms of its team
            and technical capacity.
          </p>
          <div className={styles["team__wrap"]}>
            {team.map((item) => (
              <TeamCard {...item} key={item.id} />
            ))}
          </div>
        </div>
      </section>
      <section id="contact" className={styles.contact}>
        <div className="container">
          <h4 className={styles["contact__sub-title"]}>contact us </h4>
          <h2 className={styles.contact__title}>Get in Touch</h2>
          <Form />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
