import styles from "./mint.module.scss";

const Rarity = () => {
  

  return (
    <section className={styles.rarity} id="rarity">
      <div className="container">
        <h2>Background</h2>
        <table className={styles.table}>
          <tr>
            <td>Trait</td>
            <td>%</td>
            <td>Count</td>
            <td>Rank</td>
          </tr>
        </table>
      </div>
    </section>
  );
};

export default Rarity;
