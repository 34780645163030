import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string(),
  email: yup
    .string()
    .required("Email is required field")
    .email("Email must be valid"),
  msg: yup.string(),
});
