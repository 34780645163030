import styles from './whitepapersCard.module.scss';

const WhitepapersCard = ({ img, title, link }) => {

    return (
        <div className={styles['card']}>
            <img className={styles['card__image']} src={img} alt={title} loading='lazy' />
            <h3 className={styles['card__title']}>{title}</h3>
            <a href={link} target='_blank' rel="noreferrer" className={styles['card__btn']}>Learn More</a>
        </div>
    )
}

export default WhitepapersCard;