import { Route, Routes } from 'react-router';
import './styles/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { PATHS } from './utils';
import Home from "./pages/Home";
import Mint from './pages/Mint';
import Layout from './layout';
import Rarity from './pages/Rarity';
import NotFound from './components/NotFound';


function App() {
  return (
    <Router>
      <Routes>
        <Route path={PATHS.home} element={<Home />} />
        {/* <Route path={PATHS.mint} element={<Layout><Mint /></Layout>} /> */}
        <Route path={PATHS.rarity} element={<Layout><Rarity /></Layout>} />
        <Route path='*' element={<Layout><NotFound /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;
