import styles from './team.module.scss';

const TeamCard = ({ img, title, position }) => {

    return (
        <div className={styles['card']}>
            <img className={styles['card__image']} src={img} alt={title} />
            <h3 className={styles['card__title']}>{title}</h3>
            <p className={styles['card__position']}>{position}</p>
        </div>
    )
}

export default TeamCard;