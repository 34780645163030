import {
  discord,
  facebook,
  instagram,
  linktree,
  logo,
  medium,
  tiktok,
  twitter,
  youtube,
} from "../../assets";
import styles from "./footer.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <footer className={styles.footer}>
      <div className="container">
        <img
          className={styles.footer__logo}
          src={logo}
          alt="Logo"
          loading="lazy"
        />
        <h2 className="text-uppercase">Stay Connected!</h2>
        <div className={styles.footer__social}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://linktr.ee/DigitalWebCreationClub"
         
          >
            <img src={linktree} alt="Linktree" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/dwcc"
         
          >
            <img src={discord} alt="Discord" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/SlothRoob"
         
          >
            <img src={twitter} alt="Twitter" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/TheChroniclesofRoob"
         
          >
            <img src={facebook} alt="Facebook" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/sloth_roob"
         
          >
            <img src={instagram} alt="Instagram" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCNroE867f5ixB-3Lf2wkZIg"
         
          >
            <img src={youtube} alt="Youtube" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tiktok.com/@slothroobnft"
         
          >
            <img src={tiktok} alt="TikTok" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://digitalwebcreation.medium.com"
         
          >
            <img src={medium} alt="Medium" />
          </a>
        </div>
        <p className="mb-0">
          ©{date.getFullYear()}, Digital Web Creation Club " DWCC "
        </p>
      </div>
    </footer>
  );
};

export default Footer;
