import cn from "classnames";
import { useState } from "react";
import styles from './collapse.module.scss'

const Collapse = ({ idx = 1, title = 'sas', children }) => {

    const [collapse, setCollapse] = useState({ id: null, status: false });

    const handleCollapse = (id) => {
        setCollapse({
            id,
            ...(id !== collapse.id ? { id, status: true } : { status: !collapse.status })
        })
    }
    return (
        <div key={idx} onClick={() => handleCollapse(idx)}
            className={cn(styles['collapse'], {
                [styles['collapse_active']]: collapse.id === idx && collapse.status
            })}>
            <h3 className={styles['collapse--title']}>{title}</h3>
            <div className={
                cn(styles['collapse--desc'], {
                    [styles['collapse--desc_active']]: collapse.id === idx && collapse.status
                })
            } dangerouslySetInnerHTML={{ '__html': children }} />
        </div>
    )
}

export default Collapse;