import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { logo } from "../../assets";
import styles from "./header.module.scss";
import { PATHS, menu } from "../../utils";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();

  const handleSticky = () => {
    if (window.pageYOffset > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.pageYOffset > 100 && setIsSticky(true);
    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  const handleToogleMenu = () => {
    // !isOpen
    //   ? document.body.classList.add("disableScroll")
    //   : document.body.removeAttribute("class");
    setIsOpen((prev) => !prev);
  };

  return (
    <header
      className={cn(styles.header, {
        [styles.header_fixed]: isSticky || isOpen,
        [styles.header_dark]: pathname !== PATHS.home,
      })}
    >
      <div className="container">
        <div className={styles.header__wrap}>
          <Link to="/#home" onClick={() => setIsOpen(false)}>
            <img className={styles.header__logo} src={logo} alt="Logo" />
          </Link>
          <button onClick={handleToogleMenu} className={styles.burger__btn}>
            <div
              className={cn(styles.burger__icon, {
                [styles.burger__icon_open]: isOpen,
              })}
            />
          </button>
          <ul
            className={cn(styles.menu, {
              [styles.menu__open]: isOpen,
            })}
          >
            {menu.map((item, idx) => {
              return (
                <li className={styles} key={idx}>
                  {item === "Mint" ? (
                    <Link
                      className={styles.menu__link}
                      to="/mint"
                      onClick={() => setIsOpen(false)}
                    >
                      {item}
                    </Link>
                  ) : (
                    <a
                      className={styles.menu__link}
                      href={`/#${item.toLowerCase()}`}
                      onClick={() => setIsOpen(false)}
                    >
                      {item}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
