import cn from 'classnames';
import { useState } from 'react';
import styles from './productCard.module.scss';

const ProductCard = ({ img, title, desc, id, sphere, subtitle, category, more }) => {
    const [isShowMore, setIsShowMore] = useState(false)

    return (
        <div className={styles['wrap']}>
            <div className={styles['product-card']}>
                <img className={styles['product-card__image']} src={img} alt={title} />
                <h4 className={styles['product-card__category']}>
                    <span className={styles['product-card__category_text']}>{category}</span>
                    <div className={styles['product-card__category_line']} />
                </h4>
                <h3 className={styles['product-card__title']}>{title}</h3>
                <h4 className={styles['product-card__subtitle']}>{subtitle}</h4>
                <div className={styles['product-card__desc']} dangerouslySetInnerHTML={{ __html: desc }} />
                {more && (
                    <div
                        className={cn(
                            styles['product-card__desc'],
                            styles['product-card__more'],
                            { [styles['product-card__more_active']]: isShowMore }
                        )}
                        dangerouslySetInnerHTML={{ __html: more }}
                    />
                )}
                {more &&
                    <button
                        onClick={() => setIsShowMore(prev => !prev)}
                        className={styles['product-card__btn']}
                    >
                        {isShowMore ? 'See less' : 'See more'}
                    </button>
                }
            </div>
            {sphere && (
                <img
                    className={cn(styles[`product-card__sphere`], styles[`product-card__sphere_${id}`])}
                    src={sphere}
                    alt="Sphere"
                />
            )}
        </div>
    )
}

export default ProductCard;