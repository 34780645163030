import styles from "./index.module.scss";

const NotFound = () => {
  return (
    <section className={styles["error"]}>
      <div>
        <h1>404</h1>
        <p>Page Not Found</p>
      </div>
    </section>
  );
};

export default NotFound;
