import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import styles from "./form.module.scss";
import { EMAILJS } from "../../utils";
import { schema } from "../../schema";
import Loader from "../Loader";

const Form = () => {
  useEffect(() => {
    emailjs.init(EMAILJS.publicKey);
  }, []);

  // const [emailStatus, setEmailStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    name: "",
    msg: "",
  };

  const handleSubmit = async (data, { resetForm }) => {
    setLoading(true);
    try {
      await emailjs.send(EMAILJS.serviceId, EMAILJS.templateId, data);
      resetForm({});
      // setEmailStatus(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <form className={styles.form}>
      <div className={styles.form__group}>
        <input
          onChange={formik.handleChange}
          name="name"
          className={styles.form__input}
          type="text"
          placeholder="Name"
          value={formik.values.name}
        />
      </div>
      <div className={styles.form__group}>
        <input
          onChange={formik.handleChange}
          name="email"
          className={styles.form__input}
          type="text"
          placeholder="Email"
          value={formik.values.email}
        />
        <p className={styles["form__error-text"]}>{formik.errors.email}</p>
      </div>
      <div className={styles.form__group}>
        <textarea
          onChange={formik.handleChange}
          name="msg"
          className={styles.form__msg}
          rows={4}
          type="text"
          placeholder="Message"
          value={formik.values.msg}
        />
      </div>
      <div className={styles.form__group}>
        <button
          onClick={formik.handleSubmit}
          className={styles.form__btn}
          type="button"
        >
            {loading ? <Loader /> : "send message"} 
        </button>
      </div>
    </form>
  );
};

export default Form;
