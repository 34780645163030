import styles from './index.module.scss';

const CollectionsCard = ({ title, img, soon }) => {
    return (
        <div className={styles.card}>
            <img src={img} alt="" />
            <h4 className={styles['card__title']}>
                {title}
            </h4>
            {soon && <p className={styles['card__soon']}>coming soon</p>}
        </div>
    )
}

export default CollectionsCard;